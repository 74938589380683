import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Alert, AlertColor, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import FunderForm, { funderDefaults } from './funder-form';
import { BarMsg, Funder, SnackBarMsg } from './types';

import { appInsights } from '@/application-insights';
import { removeEmptyFields } from '@/utils';

function CreateFunder() {
  const navigate = useNavigate();

  const methods = useForm<Funder>({
    defaultValues: funderDefaults,
    mode: 'onChange',
  });

  const [openBar, setOpenBar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);
  const [barMsg, setBarMsg] = useState<string>('');

  const handleCloseBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenBar(false);
    if (severity === 'success') {
      navigate('/funders');
    }
  };

  const openCreateSnackBar = (state: AlertColor | undefined) => {
    setBarMsg(SnackBarMsg[state as keyof BarMsg]);
    setSeverity(state);
    setOpenBar(true);
  };

  const onSubmit: SubmitHandler<Funder> = (funder: Funder) => {
    fetch('/api/preaward/Funder', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'x-forwarded-host': window.location.hostname,
      },
      body: JSON.stringify(removeEmptyFields<Funder>(funder)),
    })
      .then((response) => {
        openCreateSnackBar(response.ok ? 'success' : 'error');
      })
      .catch((err: Error) => {
        openCreateSnackBar('error');
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form id="createForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container width="95%" sx={{ ml: 4, mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="h4">Funder Profile</Typography>
          </Grid>

          <Grid item xs={7} sx={{ mb: 2 }}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={5} sx={{ mb: 2 }}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                id="publish"
                type="submit"
                variant="contained"
                disabled={!methods.formState.isValid}
                onClick={() => methods.setValue('status', 'Published')}
              >
                PUBLISH
              </Button>
            </Box>
          </Grid>
          <FunderForm />
          <Snackbar open={openBar} autoHideDuration={3000} onClose={handleCloseBar}>
            <Alert onClose={handleCloseBar} severity={severity} sx={{ width: '100%' }} variant="filled">
              {barMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default CreateFunder;
