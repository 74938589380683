import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Alert, AlertColor, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import FunderForm, { funderDefaults } from './funder-form';
import { BarMsg, Funder, SnackBarUpdateMsg } from './types';

import { appInsights } from '@/application-insights';
import { assignEmptyStringToNullFields, removeEmptyFields } from '@/utils';

function UpdateFunder() {
  const navigate = useNavigate();

  const methods = useForm<Funder>({
    defaultValues: funderDefaults,
    mode: 'onChange',
  });

  const [openBar, setOpenBar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);
  const [barMsg, setBarMsg] = useState<string>('');
  const params = useParams();

  useEffect(() => {
    fetch(`/api/preaward/Funder/${params.id as string}`, {
      method: 'GET',
      headers: {
        'x-forwarded-host': window.location.hostname,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 404) {
          navigate('/not-found');
        }
        return null;
      })
      .then((data: Funder) => {
        methods.reset(assignEmptyStringToNullFields<Funder>(data));
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  }, [params.id, methods, navigate]);

  const handleCloseBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenBar(false);
    if (severity === 'success') {
      navigate('/funders');
    }
  };

  const openUpdateSnackBar = (state: AlertColor | undefined) => {
    setBarMsg(SnackBarUpdateMsg[state as keyof BarMsg]);
    setSeverity(state);
    setOpenBar(true);
  };

  const onSubmit: SubmitHandler<Funder> = (funder: Funder) => {
    fetch(`/api/preaward/Funder/${params.id as string}`, {
      method: 'Put',
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'x-forwarded-host': window.location.hostname,
      },
      body: JSON.stringify(removeEmptyFields<Funder>(funder)),
    })
      .then((response) => {
        openUpdateSnackBar(response.ok ? 'success' : 'error');
      })
      .catch((err: Error) => {
        openUpdateSnackBar('error');
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form id="updateForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container width="95%" sx={{ ml: 4, mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="h4">Update Funder Profile</Typography>
          </Grid>

          <Grid item xs={7} sx={{ mb: 2 }}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={5} sx={{ mb: 2 }}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                id="update"
                type="submit"
                variant="contained"
                disabled={!methods.formState.isValid || !methods.formState.isDirty}
                onClick={() => methods.setValue('status', 'Published')}
              >
                UPDATE
              </Button>
            </Box>
          </Grid>
          <FunderForm />
          <Snackbar open={openBar} autoHideDuration={3000} onClose={handleCloseBar}>
            <Alert onClose={handleCloseBar} severity={severity} sx={{ width: '100%' }} variant="filled">
              {barMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default UpdateFunder;
