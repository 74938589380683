import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { ColumnDefinition, DropdownColumnDefinition, DropdownColumnOption, DropdownColumnOptions } from '@/types';

function DropDownColumnForm(props: Readonly<{ columns: ColumnDefinition[]; columnToEdit?: ColumnDefinition }>) {
  const {
    control,
    formState: { errors, isSubmitted, isValid },
    watch,
    getValues,
  } = useFormContext<DropdownColumnDefinition>();
  const { fields, remove, append } = useFieldArray<DropdownColumnOptions>({
    name: 'options',
  });

  function ValidateOptions(): boolean {
    const options = getValues('options');
    if (options?.length < 1) {
      return false;
    }
    return true;
  }

  function ValidateName(name: string): boolean {
    let existingColumns = props.columns;
    if (props.columnToEdit?.name !== '') {
      existingColumns = existingColumns.filter((column) => column.name !== props.columnToEdit?.name);
    }

    if (existingColumns.find((column) => column.name === name)) {
      return false;
    }
    return true;
  }

  function SetValueFields(options: DropdownColumnOption[]) {
    options?.map((option) => {
      // eslint-disable-next-line no-param-reassign
      option.value = option.name;
      return option;
    });
  }

  const optionValues = watch('options');
  SetValueFields(optionValues);

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">
            Dropdown field allows you to select a single option from predefined options in dropdown
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="name"
          defaultValue=""
          rules={{
            required: true,
            validate: {
              nameCheck: (v) => ValidateName(v) || 'Column name already exists',
              checkOptions: () => ValidateOptions(),
            },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              id="columnName"
              label="Enter Column Name"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              error={errors.name?.type === 'required' || errors.name?.type === 'nameCheck'}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color="error.main">{errors.name?.message}</Typography>
      </Grid>
      <Grid xs={12} padding={1} item>
        <Typography variant="body2">{optionValues.length > 0 ? 'Options' : 'No options defined'}</Typography>
        {fields.map((item, index) => (
          <Box key={item.id}>
            <Grid container spacing={0} alignItems="flex-end">
              <Grid item xs={11}>
                <Controller
                  name={`options.${index}.name`}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id={`options.${index}.name`}
                      autoFocus
                      margin="none"
                      fullWidth
                      size="small"
                      sx={{ mb: 2 }}
                      error={!!errors.options}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} padding={0} marginBottom={2}>
                <IconButton id={`delete.${index}.name`} aria-label="delete" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <Grid xs={12} padding={1} item>
        <Button
          id="addOption"
          onClick={() => append({ name: '', value: '' })}
          variant="outlined"
          aria-errormessage="error"
          color={isSubmitted && !isValid && errors.name?.type === 'checkOptions' ? 'error' : 'secondary'}
        >
          + Add an option
        </Button>
      </Grid>
    </Grid>
  );
}

export default DropDownColumnForm;
