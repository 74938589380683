import { Box, Grid, Paper, Typography } from '@mui/material';

import CostingsTable from './costings-table';

import { CostingSection } from '@/types';

function CostingsSection(props: { section: CostingSection; formId: string; pageId: string; readonly: boolean }) {
  const { section, formId, pageId, readonly } = props;

  return (
    <Paper elevation={2} sx={{ paddingY: 2, paddingX: 4 }}>
      <Grid container data-testid="section" spacing={0} padding={0}>
        <Grid xs={12} item key={section?.id}>
          <Box sx={{ flexGrow: 1, padding: 0 }}>
            <Typography variant="h3">{section?.name}</Typography>
            {section?.categories.map((category) => (
              <Grid xs={12} item key={category.id}>
                <Box sx={{ flexGrow: 1, padding: 0 }}>
                  <h3>{category.name}</h3>
                  <CostingsTable
                    formId={formId}
                    pageId={pageId}
                    sectionId={section.id}
                    category={category}
                    readonly={readonly}
                  />
                </Box>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CostingsSection;
