import { createTheme } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

const themeCosting = createTheme({
  palette: {
    primary: {
      main: '#777777',
      light: grey[100],
    },
    secondary: {
      main: blue[700],
    },
    warning: {
      main: red[600],
    },
    mode: 'light',
    text: {
      primary: '#777777',
    },
    background: {
      default: '#F7F8FC',
    },
    neutral: {
      main: grey[200],
      light: grey[50],
      dark: grey[400],
    },
  },
  typography: {
    body1: {
      font: 'Mulish',
      fontSize: 12,
      fontWeight: 500,
      color: grey[900],
    },
    body2: {
      font: 'Mulish',
      fontSize: 12,
      fontWeight: 400,
      color: grey[500],
    },
    h3: {
      fontWeight: 700,
      font: 'Mulish',
      color: blue[700],
      fontSize: 20,
    },
    h4: {
      fontWeight: 700,
      font: 'Mulish',
      color: red[800],
      fontSize: 18,
    },
    h6: {
      fontWeight: 500,
      font: 'Mulish',
      color: grey[900],
    },
    button: {
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 700,
      font: 'Mulish',
      color: grey[900],
      fontSize: 16,
    },
    subtitle2: {
      fontWeight: 700,
      font: 'Mulish',
      color: grey[900],
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export default themeCosting;
