import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';

import CostingsTable from './table';

import { ColumnDefinition } from '@/types';
import { ActionType, useBudgetDispatchContext } from '@forms/contexts/budget-context';
import { useFacilitiesAndServicesContext } from '@forms/contexts/facilities-services-context';
import { getColumnDefinitions } from '@forms/utils';

function TimeTable(
  props: Readonly<{
    sectionId: string;
    categoryId: string;
    columns: ColumnDefinition[];
    parentRow: GridRowModel;
    parentField: string;
    readOnly: boolean;
  }>,
) {
  const { sectionId, categoryId, readOnly } = props;
  const dispatch = useBudgetDispatchContext();
  const { facilitiesAndServicesData } = useFacilitiesAndServicesContext();

  function onDeleteRow(id: string) {
    const currentRow: GridRowModel = props.parentRow;
    currentRow[props.parentField] = [
      ...(currentRow[props.parentField] as GridRowModel[]).filter((row) => row.id !== id),
    ];
    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: props.parentRow.id as string,
        updatedRow: currentRow,
      },
    });
  }

  const columns: GridColDef[] = getColumnDefinitions(
    props.columns,
    readOnly,
    () => null,
    onDeleteRow,
    () => null,
    false,
    facilitiesAndServicesData,
    props.parentRow,
  );

  function processRowUpdate(newRow: GridRowModel) {
    const currentRow: GridRowModel = props.parentRow;
    currentRow[props.parentField] = [
      ...(currentRow[props.parentField] as GridRowModel[]).map((row) => (row.id === newRow.id ? newRow : row)),
    ];

    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: props.parentRow.id as string,
        updatedRow: currentRow,
      },
    });

    return newRow;
  }

  return (
    <CostingsTable
      columns={columns}
      rows={props.parentRow[props.parentField] as GridRowModel[]}
      onProcessRowUpdate={(newRow) => processRowUpdate(newRow)}
      onRowClick={() => null}
      uniqueKey="id"
    />
  );
}

export default TimeTable;
