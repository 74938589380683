import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, { Dispatch, ReactElement, ReactNode, createContext, useContext } from 'react';

import { CostingCategory, CostingSection } from '@/types';

export enum ActionType {
  ADD_SECTIONS = 'ADD_SECTIONS',
  UPDATE_SECTION = 'UPDATE_SECTION',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  ADD_ROW = 'ADD_ROW',
  DELETE_ROW = 'DELETE_ROW',
  UPDATE_ROW = 'UPDATE_ROW',
  SAVE_FORM = 'SAVE_FORM',
  SET_FUNDING_DETAILS = 'SET_FUNDING_DETAILS',
}

type Action =
  | { type: ActionType.ADD_SECTIONS; payload: CostingSection[] }
  | { type: ActionType.UPDATE_SECTION; payload: CostingSection }
  | {
      type: ActionType.UPDATE_CATEGORY;
      payload: { sectionId: string; category: CostingCategory };
    }
  | {
      type: ActionType.ADD_ROW;
      payload: {
        sectionId: string;
        categoryId: string;
        row: GridValidRowModel;
      };
    }
  | {
      type: ActionType.DELETE_ROW;
      payload: { sectionId: string; categoryId: string; rowId: string };
    }
  | {
      type: ActionType.UPDATE_ROW;
      payload: {
        sectionId: string;
        categoryId: string;
        existingRowId: string;
        updatedRow: GridValidRowModel;
      };
    }
  | {
      type: ActionType.SAVE_FORM;
    }
  | {
      type: ActionType.SET_FUNDING_DETAILS;
      payload: FundingDetails;
    };

export interface FundingDetails {
  funderId: string;
  pricingSchemeName?: string;
}

interface State {
  fundingDetails: FundingDetails;
  sections: CostingSection[];
  isDirty: boolean;
}

const initialState: State = {
  fundingDetails: {
    funderId: '',
    pricingSchemeName: undefined,
  },
  sections: [],
  isDirty: false,
};

const BudgetStateContext = createContext<State | undefined>(undefined);
const BudgetDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

const reducer = (state: State, action: Action): State => {
  const getSection = (sectionId: string) => state.sections.filter((sec) => sec.id === sectionId)[0];

  const getCategory = (payload: { sectionId: string; categoryId: string }) => {
    const { sectionId, categoryId } = payload;
    const currentSection = getSection(sectionId);
    const currentCategory = currentSection.categories.filter((cat) => cat.id === categoryId)[0];
    return currentCategory;
  };

  const updateSection = (section: CostingSection) => ({
    ...state,
    sections: state.sections.map((obj) => (obj.id === section.id ? section : obj)),
    isDirty: true,
  });

  const updateCategory = ({ sectionId, category }: { sectionId: string; category: CostingCategory }) => {
    const section = getSection(sectionId);
    const updatedCategories = section.categories.map((cat) => (cat.id === category.id ? category : cat));
    const updatedSection = { ...section, categories: updatedCategories };
    return updateSection(updatedSection);
  };

  const addRow = (payload: { sectionId: string; categoryId: string; row: GridRowModel }) => {
    const { sectionId, categoryId, row } = payload;
    const currentCategory = getCategory({ sectionId, categoryId });
    currentCategory.rows = currentCategory.rows.concat([row]);
    return updateCategory({ sectionId, category: currentCategory });
  };

  const updateRow = (payload: {
    sectionId: string;
    categoryId: string;
    existingRowId: string;
    updatedRow: GridRowModel;
  }) => {
    const { sectionId, categoryId, existingRowId, updatedRow } = payload;
    const currentCategory = getCategory({ sectionId, categoryId });
    currentCategory.rows = currentCategory.rows.map((row) => (row.id === existingRowId ? updatedRow : row));
    return updateCategory({ sectionId, category: currentCategory });
  };

  const deleteRow = (payload: { sectionId: string; categoryId: string; rowId: string }) => {
    const { sectionId, categoryId, rowId } = payload;
    const currentCategory = getCategory({ sectionId, categoryId });

    const rowsExcludingDeleted = currentCategory.rows.filter((row) => row.id !== rowId);
    currentCategory.rows = rowsExcludingDeleted;

    return updateCategory({ sectionId, category: currentCategory });
  };

  const setFundingDetails = (fundingDetails: FundingDetails) => ({
    ...state,
    fundingDetails,
  });

  switch (action.type) {
    case ActionType.ADD_SECTIONS:
      return { ...state, sections: action.payload };
    case ActionType.UPDATE_SECTION: {
      return updateSection(action.payload);
    }
    case ActionType.UPDATE_CATEGORY: {
      return updateCategory(action.payload);
    }
    case ActionType.ADD_ROW: {
      return addRow(action.payload);
    }
    case ActionType.DELETE_ROW: {
      return deleteRow(action.payload);
    }
    case ActionType.UPDATE_ROW: {
      return updateRow(action.payload);
    }
    case ActionType.SAVE_FORM: {
      return {
        ...state,
        isDirty: false,
      };
    }
    case ActionType.SET_FUNDING_DETAILS: {
      return setFundingDetails(action.payload);
    }
    default:
      return state;
  }
};

function BudgetContextProvider({ children }: Readonly<{ children: ReactNode }>): ReactElement {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <BudgetStateContext.Provider value={state}>
      <BudgetDispatchContext.Provider value={dispatch}>{children}</BudgetDispatchContext.Provider>
    </BudgetStateContext.Provider>
  );
}

const useBudgetStateContext = (): State => {
  const contextValue = useContext(BudgetStateContext);

  if (!contextValue) {
    throw new Error('useBudgetStateContext must be used within a BudgetStateProvider');
  }

  return contextValue;
};

const useBudgetDispatchContext = (): Dispatch<Action> => {
  const contextValue = useContext(BudgetDispatchContext);

  if (!contextValue) {
    throw new Error('useBudgetDispatchContext must be used within a BudgetDispatchContext');
  }

  return contextValue;
};

export { useBudgetStateContext, useBudgetDispatchContext, BudgetContextProvider, reducer };
