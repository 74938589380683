import { ReactElement, createContext, useContext, useMemo, useState } from 'react';

import { ChildrenType, FacilitiesAndServicesData } from '@/types';

type FacilitiesAndServiceState = {
  facilitiesAndServicesData: FacilitiesAndServicesData[];
  setFacilitiesAndServicesData: (data: FacilitiesAndServicesData[]) => void;
};

const initialState: FacilitiesAndServiceState = {
  facilitiesAndServicesData: [],
  setFacilitiesAndServicesData: () => {},
};

const FacilitiesAndServicesContext = createContext<FacilitiesAndServiceState>(initialState);

export function FacilitiesAndServicesContextProvider({ children }: Readonly<ChildrenType>): ReactElement {
  const [facilitiesAndServicesData, setFacilitiesAndServicesData] = useState<FacilitiesAndServicesData[]>([]);

  const value = useMemo(
    () => ({
      facilitiesAndServicesData,
      setFacilitiesAndServicesData,
    }),
    [facilitiesAndServicesData, setFacilitiesAndServicesData],
  );
  return <FacilitiesAndServicesContext.Provider value={value}>{children}</FacilitiesAndServicesContext.Provider>;
}

export const useFacilitiesAndServicesContext = () => useContext(FacilitiesAndServicesContext);
