import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { CSSProperties } from 'react';

export enum SummaryTileType {
  ProjectCost = 'Project Cost',
  FunderCost = 'Funder Cost',
  Income = 'Income',
  FECRecovery = 'FEC Recovery',
  OverheadsContribution = 'Overheads Contribution',
}

const summaryTileStyle: CSSProperties = {
  border: 1,
  borderColor: 'divider',
  borderRadius: 3,
  marginTop: 0.5,
  marginBottom: 0.5,
  padding: 0.5,
};

function Icon(props: Readonly<{ type: SummaryTileType }>) {
  const iconMap = {
    [SummaryTileType.ProjectCost]: <RequestQuoteOutlinedIcon color="primary" fontSize="large" />,
    [SummaryTileType.FunderCost]: <PaidOutlinedIcon color="primary" fontSize="large" />,
    [SummaryTileType.Income]: <AccountBalanceOutlinedIcon color="primary" fontSize="large" />,
    [SummaryTileType.FECRecovery]: <CurrencyExchangeOutlinedIcon color="primary" fontSize="large" />,
    [SummaryTileType.OverheadsContribution]: <TollOutlinedIcon color="primary" fontSize="large" />,
  };

  return iconMap[props.type];
}

function SummaryTile(props: Readonly<{ summaryTileType: SummaryTileType; value: string }>) {
  return (
    <Box sx={summaryTileStyle}>
      <Grid item xs={12} sx={{ display: 'flex', p: 0.5, pl: 0 }}>
        <Box display="flex" justifyContent="center" alignItems="center" marginX={1}>
          <Icon type={props.summaryTileType} />
        </Box>
        <Box paddingLeft={1}>
          <Typography variant="subtitle2">{props.summaryTileType}</Typography>
          <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
            {props.value}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
}

export default SummaryTile;
