import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

const addTableColumnDefinition = (colDefs: GridColDef, handleNestedTableButtonClick: () => void) => ({
  ...colDefs,
  headerClassName: 'grid-header-theme',
  sortable: false,
  editable: false,
  renderCell: () => (
    <Button size="small" variant="contained" onClick={handleNestedTableButtonClick}>
      {colDefs.headerName}
    </Button>
  ),
});

export default addTableColumnDefinition;
