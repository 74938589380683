import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Grid, IconButton } from '@mui/material';
import { useState } from 'react';

import AddColumnDialog from './add-column/add-column-dialog';
import CustomCostingsTable from './table';

import { useCostingReviewContext } from '@/review/costings-review-context';
import { ColumnDefinition, CostingCategory, Status } from '@/types';

function CostingsTable(props: { readonly sectionId: string; readonly category: CostingCategory }) {
  const rows = [{ id: 1 }];
  const { addColumn, updateColumn, deleteColumn } = useCostingReviewContext();
  const { category, sectionId } = props;

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<Status>(Status.ADD);
  const [columnToEdit, setColumnToEdit] = useState<ColumnDefinition>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function getColumns(): ColumnDefinition[] {
    return category.columnDefinitions;
  }

  const handleDeleteColumn = (columnName: string) => {
    deleteColumn(props.sectionId, category.id, columnName);
  };

  const columns = getColumns();

  const handleEditColumn = (columnName: string) => {
    setStatus(Status.UPDATE);
    setColumnToEdit(columns.find((name) => name.name === columnName));
    handleOpen();
  };

  return (
    <Box>
      <Grid container>
        <Grid xs={11} item>
          <Box sx={{ height: 150, width: '100%' }}>
            <CustomCostingsTable
              deleteColumn={handleDeleteColumn}
              editColumn={handleEditColumn}
              columns={columns}
              rows={rows}
            />
          </Box>
        </Grid>
        <Grid xs={1} item>
          <IconButton
            onClick={() => {
              setStatus(Status.ADD);
              setColumnToEdit(undefined);
              handleOpen();
            }}
            sx={{ marginTop: 2 }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
      <AddColumnDialog
        status={status}
        columnToEdit={columnToEdit}
        sectionId={sectionId}
        categoryId={category.id}
        columns={category.columnDefinitions}
        open={open}
        handleClose={handleClose}
        handleAdd={addColumn}
        handleUpdate={updateColumn}
      />
    </Box>
  );
}

export default CostingsTable;
