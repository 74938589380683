import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { Guid } from 'typescript-guid';

import TimeTable from './time-table';

import { ColumnDefinition } from '@/types';
import { ActionType, useBudgetDispatchContext } from '@forms/contexts/budget-context';
import { getColumnDefaultValue } from '@forms/utils';

function TimeTableDialog(
  props: Readonly<{
    sectionId: string;
    categoryId: string;
    columns: ColumnDefinition[];
    parentField: string;
    parentRow: GridRowModel;
    open: boolean;
    handleClose: () => void;
    readOnly: boolean;
  }>,
) {
  const { sectionId, categoryId, columns, open, handleClose, readOnly } = props;
  const dispatch = useBudgetDispatchContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const defaultRow = columns.reduce(
    (acc, columnDefinition) => {
      const { name, $type } = columnDefinition;
      return { ...acc, [name]: getColumnDefaultValue($type, name) };
    },
    { id: '1' },
  );

  function addNewRow() {
    const newRow = { ...defaultRow, id: Guid.create().toString() };
    const currentRow: GridRowModel = props.parentRow;
    currentRow[props.parentField] = [...(currentRow[props.parentField] as GridRowModel[]), newRow];

    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: props.parentRow.id as string,
        updatedRow: currentRow,
      },
    });
  }

  return (
    <Dialog
      id="time-table-dialog"
      fullScreen={fullScreen}
      open={open}
      onClose={() => {}}
      aria-labelledby="time-table-dialog-title"
      aria-describedby="time-table-dialog-description"
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="span">
              {props.parentField}
            </Typography>
            {!readOnly && (
              <Button
                variant="outlinedAction"
                id="nestedAddRowButton"
                startIcon={<AddIcon />}
                onClick={() => addNewRow()}
              >
                Add
              </Button>
            )}
          </Box>
          <Button variant="dialogAction" endIcon={<CloseIcon />} onClick={handleClose}>
            Done
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TimeTable
          sectionId={sectionId}
          categoryId={categoryId}
          columns={columns}
          parentRow={props.parentRow}
          parentField={props.parentField}
          readOnly={readOnly}
        />
      </DialogContent>
    </Dialog>
  );
}

export default TimeTableDialog;
