import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import {
  DataGridPro,
  FooterPropsOverrides,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { isDeepEqual } from '@mui/x-data-grid-pro/internals';
import { useEffect, useState } from 'react';

import { useCostingFormContext } from '@/forms/contexts/costings-forms-context';
import { ColumnDefinition, ColumnValueType, CostingCategory, DropdownColumnDefinition, SectionTotal } from '@/types';

interface DataGridProps {
  formId: string;
  pageId: string;
  sectionId: string;
  category: CostingCategory;
  readonly: boolean;
}

function CustomFooter(props: FooterPropsOverrides) {
  const { total } = props;
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#E8EBF0',
        height: 40,
        paddingLeft: 1,
        alignContent: 'center',
      }}
    >
      <Grid xs={11} item>
        <Typography variant="subtitle2">Subtotal</Typography>
      </Grid>
      <Grid xs={1} item data-testid="total">
        <Typography variant="subtitle2">
          {total.toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}

function CostingsTable(props: DataGridProps) {
  const { formId, pageId, sectionId, category, readonly } = props;
  const { costingSections, addCategoryTotal, addRow, updateRow, deleteRow } = useCostingFormContext();

  const { columnDefinitions, rows } = category;
  const apiRef = useGridApiRef();

  const getDefault = ($type: ColumnValueType) => {
    if ($type === ColumnValueType.text || $type === ColumnValueType.dropdown) {
      return '';
    }

    if ($type === ColumnValueType.number || $type === ColumnValueType.calculated) {
      return 0;
    }

    return 0;
  };
  const defaultRow = columnDefinitions.reduce(
    (acc, columnDefinition) => {
      const { name, $type } = columnDefinition;
      return { ...acc, [name]: getDefault($type) };
    },
    { id: '1' },
  );
  const [total, setTotal] = useState(0);

  function getSectionName(secId: string): string {
    const sectionName = costingSections?.find((section) => section.id === secId)?.name;
    return sectionName || '';
  }

  function updateBannerValues(tot: number) {
    const sectionName = getSectionName(sectionId);
    const sectionTotal: SectionTotal = {
      sectionId,
      sectionName,
      categoryId: category.id,
      total: tot,
    };

    if (sectionName) {
      addCategoryTotal(sectionTotal);
    }
  }

  useEffect(() => {
    const newTotal = rows.reduce((acc: number, curr: GridRowModel) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const lastColumnValue: string = curr[Object.keys(curr)[Object.keys(curr).length - 1]];

      const current: number = parseFloat(lastColumnValue);
      return acc + current;
    }, 0);
    setTotal(newTotal);

    updateBannerValues(newTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  function getColumns(): GridColDef[] {
    const alignValue: GridAlignment = 'left';
    const columns = columnDefinitions.map((column: ColumnDefinition) => {
      const gridColumnDefinition = {
        field: column.name,
        headerName: column.name,
        headerClassName: 'grid-header-theme',
        cellClassName: 'grid-cell-theme',
        sortable: false,
        flex: 1,
        editable: column.$type !== ColumnValueType.calculated,
        headerAlign: alignValue,
        align: alignValue,
      };

      if (column.$type === ColumnValueType.text) {
        return { ...gridColumnDefinition, type: 'string', editable: !readonly };
      }

      if (column.$type === ColumnValueType.number) {
        return { ...gridColumnDefinition, type: 'number', editable: !readonly };
      }

      if (column.$type === ColumnValueType.calculated) {
        return {
          ...gridColumnDefinition,
          type: 'number',
          editable: false,
        };
      }

      if (column.$type === ColumnValueType.dropdown) {
        const ddColumn = column as DropdownColumnDefinition;

        return {
          ...gridColumnDefinition,
          type: 'singleSelect',
          editable: !readonly,
          valueOptions: ddColumn.options.map((option) => option.value),
          renderCell: (cellValues: GridRenderCellParams) =>
            cellValues.value ? (
              <Grid container>{cellValues.value}</Grid>
            ) : (
              <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
                <Grid item xs={10}>
                  {`Select ${ddColumn.name}`}
                </Grid>

                <Grid item xs={2} sx={{ paddingTop: 0.6 }}>
                  <ArrowDropDownIcon color="primary" />
                </Grid>
              </Grid>
            ),
        };
      }

      return gridColumnDefinition;
    });

    return [
      ...columns,
      {
        field: 'actions',
        headerName: 'Actions',
        headerClassName: 'grid-header-theme',
        sortable: false,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          readonly || (
            <IconButton onClick={() => deleteRow(sectionId, category.id, params.id.toString())} sx={{}}>
              <DeleteIcon />
            </IconButton>
          ),
      },
    ];
  }
  const columns: GridColDef[] = getColumns();

  async function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel) {
    const isMutated = isDeepEqual(newRow, oldRow) === false;
    if (!isMutated) {
      return oldRow;
    }

    const response = await fetch(`/api/preaward/costings/calculate`, {
      method: 'POST',
      body: JSON.stringify({
        formId,
        pageId,
        sectionId,
        categoryId: category.id,
        dataTable: [newRow],
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      return oldRow;
    }

    const updatedRow: GridRowModel = await response.json().then((dataTable: GridRowModel[]) => dataTable[0]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    updateRow(sectionId, category.id, (newRow as GridRowModel).id.toString(), updatedRow);
    return updatedRow;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            minHeight: 150,
            width: '100%',
            '& .grid-header-theme': {
              backgroundColor: '#E8EBF0',
              typography: 'subtitle2',
            },
            '& .grid-cell-theme': {
              typography: 'body1',
            },
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            autoHeight
            rowHeight={40}
            columnHeaderHeight={40}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            hideFooterPagination
            hideFooterRowCount
            hideFooterSelectedRowCount
            slots={{
              footer: CustomFooter,
            }}
            slotProps={{
              footer: { total },
            }}
            processRowUpdate={(oldRow: GridRowModel, newRow: GridRowModel) => processRowUpdate(oldRow, newRow)}
          />
        </Box>
      </Grid>
      {readonly || (
        <Grid xs={12} sx={{ paddingTop: 2 }} item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const newRow = { ...defaultRow };
              newRow.id = (rows.length + 1).toString();
              return addRow(sectionId, category.id, newRow);
            }}
          >
            Add New Cost Item
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default CostingsTable;
