import { Avatar, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

const colors = ['#D04F32', '#3357FF', '#C93584', '#FFBD33', '#8D33FF', '#692A2A', '#2DA881', '#FFA833'];

function getColorFromName(name: string) {
  const charSum = name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  return colors[charSum % colors.length];
}

export default function NameAvatarCell(props: Readonly<{ text: string }>) {
  if (!props.text) return null;

  const names = props.text.split(' ');
  const initials = names.map((name) => name[0]).join('');

  const color = getColorFromName(props.text);

  return (
    <Grid container>
      <Box>
        <Avatar sx={{ bgcolor: color, width: 24, height: 24, mr: 1 }}>
          <Typography variant="body2">{initials}</Typography>
        </Avatar>
      </Box>
      <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {props.text}
      </Box>
    </Grid>
  );
}
