import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import StaffAutocomplete from './staff-autocomplete';

import NameAvatarCell from '@/components/name-avatar-cell';
import { ColumnDefinition, ExternalColumnDefinition, StaffModel } from '@/types';
import { lowerFirstLetter } from '@/utils';

function addExternalFilterableColumnDefinition(colDefs: GridColDef, column: ColumnDefinition, readonly: boolean) {
  const externalColumnDefinition = column as ExternalColumnDefinition;
  const propertyName = lowerFirstLetter(externalColumnDefinition.propertyName) as keyof StaffModel;
  return {
    ...colDefs,
    type: 'singleSelect',
    editable: !readonly,
    renderCell: (params: GridRenderCellParams) => NameAvatarCell({ text: params.value as string }),
    renderEditCell: (params: GridRenderCellParams) => (
      <StaffAutocomplete
        {...params}
        path={externalColumnDefinition.path}
        propertyName={externalColumnDefinition.propertyName}
      />
    ),
    valueParser: (value: StaffModel) => value[propertyName],
  };
}

export default addExternalFilterableColumnDefinition;
