import { Box, Typography, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AdminAppBar from '@/admin/components/admin-appbar';
import UploadManager, { TabConfig } from '@/admin/components/upload-manager';

const Root = styled(Box)({
  flexGrow: 1,
});

const BackLink = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
}));

function UploadsTileTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigateBack = useCallback(() => {
    navigate('/admin');
  }, [navigate]);

  return (
    <Root>
      <AdminAppBar title="Staff Pay Scales" />
      <Box sx={{ pl: 4 }}>
        <BackLink variant="body2" color="primary" onClick={handleNavigateBack}>
          ← Admin Configuration
        </BackLink>
      </Box>

      <UploadManager tabs={location.state as TabConfig[]} />
    </Root>
  );
}

export default React.memo(UploadsTileTabs);
