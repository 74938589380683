import { GridRowModel } from '@mui/x-data-grid-pro';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { CostingCategory, CostingSection, SectionTotal } from '@/types';

type StateType = {
  costingSections: CostingSection[];
  categoryTotals: SectionTotal[];
  addCategoryTotal: (sectionTotal: SectionTotal) => void;
  setSections: (sections: CostingSection[]) => void;
  addRow: (sectionId: string, categoryId: string, row: GridRowModel) => void;
  updateRow: (sectionId: string, categoryId: string, existingRowId: string, updatedRow: GridRowModel) => void;
  deleteRow: (sectionId: string, categoryId: string, rowId: string) => void;
  changesDetected: boolean;
  setChangesDetected: (changesDetected: boolean) => void;
};

const initialState: StateType = {
  costingSections: [],
  categoryTotals: [],
  addCategoryTotal: () => {},
  setSections: () => {},
  addRow: () => {},
  updateRow: () => {},
  deleteRow: () => {},
  changesDetected: false,
  setChangesDetected: () => {},
};

const SectionContext = createContext<StateType>(initialState);

type ChildrenType = {
  children: ReactNode;
};

export function CostingFormContextProvider({ children }: ChildrenType): ReactElement {
  const [costingSections, setCostingSections] = useState<CostingSection[]>([]);
  const [categoryTotals, setCategoryTotals] = useState<SectionTotal[]>([]);
  const [changesDetected, setChangesDetected] = useState<boolean>(false);

  const setSections = (sections: CostingSection[]) => {
    setCostingSections(sections);
  };

  const updateSection = useCallback(
    (section: CostingSection) => {
      const updatedSections = costingSections.map((obj) => {
        if (obj.id === section.id) {
          return section;
        }
        return obj;
      });
      setCostingSections(updatedSections);
    },
    [costingSections],
  );

  const addCategoryTotal = useCallback(
    (sectionTotal: SectionTotal) => {
      setCategoryTotals((oldArray) => {
        const index = categoryTotals.findIndex((o) => o.categoryId === sectionTotal.categoryId);
        if (index !== -1) {
          return oldArray.map((obj) => {
            if (obj.categoryId === oldArray[index].categoryId) {
              return sectionTotal;
            }
            return obj;
          });
        }
        return [...oldArray, sectionTotal];
      });
    },
    [categoryTotals],
  );

  const updateCategory = useCallback(
    (sectionId: string, category: CostingCategory) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      const updatedCategories = currentSection?.categories.map((obj) => {
        if (obj.id === category.id) {
          return category;
        }
        return obj;
      });

      if (currentSection) {
        currentSection.categories = updatedCategories || [];

        updateSection(currentSection);
      }
    },
    [costingSections, updateSection],
  );

  const addRow = useCallback(
    (sectionId: string, categoryId: string, row: GridRowModel) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        currentCategory.rows = (currentCategory?.rows || []).concat([row]);
        updateCategory(sectionId, currentCategory);
        setChangesDetected(true);
      }
    },
    [costingSections, updateCategory],
  );

  const updateRow = useCallback(
    (sectionId: string, categoryId: string, existingRowId: string, updatedRow: GridRowModel) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        const updatedRows = currentCategory?.rows.map((row) => {
          if (row.id === existingRowId) {
            return updatedRow;
          }
          return row;
        });

        currentCategory.rows = updatedRows || [];
        updateCategory(sectionId, currentCategory);
        setChangesDetected(true);
      }
    },
    [costingSections, updateCategory],
  );

  const deleteRow = useCallback(
    (sectionId: string, categoryId: string, rowId: string) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        const rowsExcludingDeleted = currentCategory?.rows.filter((row) => row.id !== rowId);
        currentCategory.rows = rowsExcludingDeleted || [];
        updateCategory(sectionId, currentCategory);
        setChangesDetected(true);
      }
    },
    [costingSections, updateCategory],
  );

  const value = useMemo(
    () => ({
      costingSections,
      categoryTotals,
      setSections,
      addCategoryTotal,
      addRow,
      updateRow,
      deleteRow,
      changesDetected,
      setChangesDetected,
    }),
    [
      addCategoryTotal,
      categoryTotals,
      costingSections,
      addRow,
      updateRow,
      deleteRow,
      changesDetected,
      setChangesDetected,
    ],
  );
  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
}

export const useCostingFormContext = () => useContext(SectionContext);
