import { MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import countries, { Country } from 'country-list';
import { Controller, useFormContext } from 'react-hook-form';
import validator from 'validator';

import { funderTypes } from './types';

export const funderDefaults = {
  name: '',
  funderType: '',
  description: '',
  email: '',
  url: '',
  status: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postCode: '',
    country: '',
    county: '',
  },
};

function FunderForm() {
  const countryList: Country[] = countries.getData();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container>
      <Grid container width="70%">
        <Grid item xs={6} sx={{ pr: 1, mb: 2 }}>
          <Controller
            name="name"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <TextField
                id="name"
                label="Name *"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="medium"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ pl: 1, mb: 2 }}>
          <Controller
            name="funderType"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                id="funderType"
                select
                value={value as string}
                fullWidth
                size="medium"
                label="Funder Type *"
                InputLabelProps={{ shrink: true }}
                onChange={onChange}
              >
                {funderTypes.map((funder: string) => (
                  <MenuItem key={funder} value={funder}>
                    {funder}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                id="description"
                label="Description"
                fullWidth
                multiline
                rows={2}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="email"
            control={control}
            rules={{
              validate: {
                emailCheck: (v: string) => (v === '' ? true : validator.isEmail(v)) || 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                id="email"
                label="Email"
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? (errors.email.message as string) : ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="url"
            control={control}
            rules={{
              validate: {
                urlCheck: (v: string) => (v === '' ? true : validator.isURL(v)) || 'Invalid url',
              },
            }}
            render={({ field }) => (
              <TextField
                id="url"
                label="URL"
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!errors.url}
                helperText={errors.url ? (errors.url.message as string) : ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.addressLine1"
            control={control}
            render={({ field }) => (
              <TextField
                id="addressLine1"
                label="Address Line 1"
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.addressLine2"
            control={control}
            render={({ field }) => (
              <TextField
                id="addressLine2"
                label="Address Line 2"
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.addressLine3"
            control={control}
            render={({ field }) => (
              <TextField
                id="addressLine3"
                label="Address Line 3"
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => (
              <TextField id="city" label="City" InputLabelProps={{ shrink: true }} fullWidth {...field} />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.county"
            control={control}
            render={({ field }) => (
              <TextField id="county" label="County" InputLabelProps={{ shrink: true }} fullWidth {...field} />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.country"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                id="country"
                label="Country"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={value as string}
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: 500 },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root': {
                          paddingBottom: '8px',
                          paddingTop: '8px',
                        },
                      },
                    },
                  },
                }}
                onChange={onChange}
              >
                {countryList.map((item: Country) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Controller
            name="address.postCode"
            control={control}
            render={({ field }) => (
              <TextField id="postCode" label="Post Code" InputLabelProps={{ shrink: true }} fullWidth {...field} />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FunderForm;
