import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CssBaseline, Grid, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import CostingsBanner from './costings-banner';
import CostingsSection from './costings-section';

import { appInsights } from '@/application-insights';
import { useCostingFormContext } from '@/forms/contexts/costings-forms-context';
import themeCosting from '@/themeCosting';
import { CostingsDto } from '@/types';

function CostingsSubmission() {
  const { costingSections, setSections } = useCostingFormContext();
  const [searchParams] = useSearchParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const formId = searchParams.get('formId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageId = searchParams.get('pageId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const submissionId = searchParams.get('submissionId')!;

  useEffect(() => {
    fetch(`/api/preaward/costings/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`)
      .then((response) => response.json())
      .then((data: CostingsDto) => {
        setSections(data.sections);
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, pageId, submissionId]);

  return (
    <ThemeProvider theme={themeCosting}>
      <CssBaseline>
        <Grid container paddingY={2}>
          <Grid item xs={0.5} />
          <Grid item xs={11}>
            <Grid container paddingBottom={2}>
              <Grid item xs={12}>
                <CostingsBanner />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {costingSections?.map((section) => (
                <Grid item xs={12} key={section.id}>
                  <CostingsSection section={section} formId={formId} pageId={pageId} readonly />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default CostingsSubmission;
