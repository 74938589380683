import {
  GRID_DATE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridValueFormatterParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { dateFormat } from '@/utils';

interface GridEditDateCellProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends Readonly<GridRenderEditCellParams<any, Dayjs | null, string>> {
  id: GridRowId;
  field: string;
  value: Dayjs | null;
}

function GridEditDateCell(props: Readonly<GridEditDateCellProps>) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (newValue: unknown) => {
    await apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <DatePicker value={dayjs(value)} format={dateFormat} autoFocus onChange={handleChange} />;
}

const addDateTimeColumnDefinition = (readOnly: boolean, colDefs: GridColDef) => ({
  ...GRID_DATE_COL_DEF,
  ...colDefs,
  resizable: false,
  editable: !readOnly,
  renderEditCell: (params: GridRenderCellParams) => (
    <GridEditDateCell {...params} value={dayjs(params.value as Date)} />
  ),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  valueFormatter: (value: GridValueFormatterParams<unknown>) => dayjs(value.value as string).format(dateFormat),
});

export default addDateTimeColumnDefinition;
