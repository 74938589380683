import { Help, MenuRounded, Notifications } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography, styled } from '@mui/material';
import React from 'react';

const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: 'transparent',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: 'transparent',
  height: 48,
  padding: theme.spacing(0.5, 0),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(1),
}));

const StyledIconButton = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const Avatar = styled(Box)(({ theme }) => ({
  width: 28,
  height: 28,
  fontSize: 12,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
}));

interface AdminAppBarProps {
  title: string;
  onMenuClick?: () => void;
  onHelpClick?: () => void;
  onNotificationsClick?: () => void;
  avatarText?: string;
}

function AdminAppBar({
  title,
  onMenuClick,
  onHelpClick,
  onNotificationsClick,
  avatarText,
}: Readonly<AdminAppBarProps>) {
  return (
    <StyledAppBar position="static" color="transparent" elevation={0}>
      <StyledToolbar>
        <StyledIconButton edge="start" color="inherit" aria-label="Open menu" onClick={onMenuClick}>
          <MenuRounded />
        </StyledIconButton>
        <StyledTitle variant="h5">{title}</StyledTitle>
        <StyledIconButton color="inherit" aria-label="Help" onClick={onHelpClick}>
          <Help />
        </StyledIconButton>
        <StyledIconButton color="inherit" aria-label="Notifications" onClick={onNotificationsClick}>
          <Notifications />
        </StyledIconButton>
        <Avatar>{avatarText}</Avatar>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default React.memo(AdminAppBar);
