import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import { ColumnDefinition, ExternalColumnDefinition, ExternalData, FacilitiesAndServicesData } from '@/types';
import { lowerFirstLetter } from '@/utils';
import { useFacilitiesAndServicesContext } from '@forms/contexts/facilities-services-context';
import preawardServiceApi from '@forms/services/preaward-api';

function AddExternalColumnDefinition(colDefs: GridColDef, column: ColumnDefinition, readonly: boolean) {
  const { setFacilitiesAndServicesData } = useFacilitiesAndServicesContext();
  const externalColumnDefinition = column as ExternalColumnDefinition;
  const { path } = externalColumnDefinition;
  const [externalDataList, setExternalDataList] = useState<ExternalData[]>([]);
  const propertyName = lowerFirstLetter(externalColumnDefinition.propertyName) as keyof ExternalData;

  useEffect(() => {
    const getExternalData = async () => {
      const data = await preawardServiceApi.getExternalData(path);
      setExternalDataList(data.data);
      if (path === '/FacilitiesAndServices') setFacilitiesAndServicesData(data.data as FacilitiesAndServicesData[]);
    };

    getExternalData().catch(() => {});
  }, [externalColumnDefinition, path, propertyName, setFacilitiesAndServicesData]);

  return {
    ...colDefs,
    type: 'singleSelect',
    editable: !readonly,
    valueOptions: externalDataList.map((data) => data[propertyName]),
    renderCell: (cellValues: GridRenderCellParams) =>
      cellValues.value ? (
        <Grid container>{cellValues.value}</Grid>
      ) : (
        <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
          <Grid item xs={10}>
            Select
          </Grid>

          <Grid item xs={2} sx={{ paddingTop: 0.6 }}>
            <ArrowDropDownIcon color="primary" />
          </Grid>
        </Grid>
      ),
  };
}

export default AddExternalColumnDefinition;
