import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CalculatedColumnDefinition, ColumnDefinition } from '@/types';

function CalculateColumForm(props: Readonly<{ columns: ColumnDefinition[]; columnToEdit?: ColumnDefinition }>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<CalculatedColumnDefinition>();

  function ValidateName(name: string): boolean {
    let existingColumns = props.columns;
    if (props.columnToEdit?.name !== '') {
      existingColumns = existingColumns.filter((column) => column.name !== props.columnToEdit?.name);
    }

    if (existingColumns.find((column) => column.name === name)) {
      return false;
    }
    return true;
  }

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">Calculated field allows you to enter data value</Typography>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="name"
          defaultValue=""
          rules={{
            required: true,
            validate: {
              nameCheck: (v: string) => ValidateName(v) || 'Column name already exists',
            },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              id="columnName"
              label="Enter Column Name"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              error={!!errors.name}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid xs={12} sx={{ mb: 2 }} item>
        <Typography color="error.main">{errors.name?.message}</Typography>
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="expression"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <TextField
              id="columnExpression"
              label="Enter Expression"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default CalculateColumForm;
