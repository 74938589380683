import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfirmProvider } from 'material-ui-confirm';

import Main from './Main';
import AzureAppInsights from './application-insights';
import themePortal from './themePortal';

LicenseInfo.setLicenseKey(
  '260719ebc172e85306418c8a9b380bd5Tz04OTU0MyxFPTE3NDYxODIzMjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function App() {
  return (
    <ThemeProvider theme={themePortal}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmProvider>
            <AzureAppInsights>
              <Main />
            </AzureAppInsights>
          </ConfirmProvider>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
