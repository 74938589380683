import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Box, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { appInsights } from '@/application-insights';
import { ColumnDefinition, ExternalColumnDefinition, ExternalDataType, ExternalDataTypes } from '@/types';

function ExternalColumnForm(
  props: Readonly<{
    columns: ColumnDefinition[];
    columnToEdit?: ColumnDefinition;
  }>,
) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<ExternalColumnDefinition>();
  const [externalDataTypes, setExternalDataTypes] = useState<ExternalDataType[]>([]);
  const [currentProperties, setCurrentProperties] = useState<string[]>([]);

  function ValidateName(name: string): boolean {
    let existingColumns = props.columns;
    if (props.columnToEdit?.name !== '') {
      existingColumns = existingColumns.filter((column) => column.name !== props.columnToEdit?.name);
    }

    if (existingColumns.find((column) => column.name === name)) {
      return false;
    }
    return true;
  }

  const setCurrentPropertyNames = useCallback(
    (newSourceValue?: string) => {
      const sourcePath = newSourceValue ?? (props.columnToEdit as ExternalColumnDefinition).path;
      const selectedSource = externalDataTypes.find((source) => source.path === sourcePath);

      if (selectedSource) {
        setCurrentProperties(selectedSource.propertyNames);
        setValue('filterable', selectedSource.filterable);
      }
    },
    [externalDataTypes, props.columnToEdit, setValue],
  );

  useEffect(() => {
    fetch('/api/preaward/ExternalData', {
      method: 'GET',
      headers: {
        'x-forwarded-host': window.location.hostname,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((data: ExternalDataTypes) => {
        setExternalDataTypes(data.externalDataTypes);
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPropertyNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalDataTypes]);

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">External field allows you to define external data source</Typography>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="name"
          defaultValue=""
          rules={{
            required: true,
            validate: {
              nameCheck: (v: string) => ValidateName(v) || 'Column name already exists',
            },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              id="columnName"
              label="Enter Column Name"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              error={!!errors.name}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography color="error.main">{errors.name?.message}</Typography>
      </Grid>
      <Grid xs={12} item sx={{ mb: 2 }}>
        <Controller
          name="path"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value, name, ref } }) => (
            <TextField
              select
              fullWidth
              size="small"
              id="externalSource"
              label="External Source"
              onChange={(event) => {
                const newValue = event.target.value;
                onChange(newValue);
                setCurrentPropertyNames(newValue);
                setValue('propertyName', '');
              }}
              ref={ref}
              name={name}
              value={externalDataTypes.length > 0 ? value : ''}
            >
              <MenuItem disabled value="">
                Select...
              </MenuItem>
              {externalDataTypes.map((item) => (
                <MenuItem key={item.name} value={item.path}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="propertyName"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value, name, ref } }) => (
            <TextField
              select
              fullWidth
              size="small"
              id="propertyName"
              label="Property Name"
              onChange={(newValue) => {
                onChange(newValue);
              }}
              ref={ref}
              name={name}
              value={currentProperties.length > 0 ? value : ''}
            >
              <MenuItem disabled value="">
                Select...
              </MenuItem>
              {currentProperties.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ExternalColumnForm;
