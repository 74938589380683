import { DownloadRounded } from '@mui/icons-material';
import { Alert, Box, Snackbar, Tab, Tabs, Typography, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';

import UploadDialog from './upload-dialog';
import UploadsActionButton from './uploads-action-button';

import preawardAdminServiceApi, { UploadType } from '@/admin/services/preaward-api';

const SNACKBAR_AUTO_HIDE_DURATION = 3000;

const StyledLayout = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export interface TabConfig {
  label: string;
  type: UploadType;
  stateTitle: string;
  stateButtonTitle: string;
  stateButtonSubtitle: string;
}

interface UploadManagerProps {
  tabs: TabConfig[];
}

function UploadManager({ tabs }: Readonly<UploadManagerProps>) {
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const activeTabConfig = tabs[activeTab];

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbar = useCallback((message: string, isError = false) => {
    setSnackbarMessage(message);
    setError(isError ? message : null);
    setSnackbarOpen(true);
  }, []);

  const handleOnImport = useCallback(
    async (file: File) => {
      try {
        const success = await preawardAdminServiceApi.UploadFile(activeTabConfig.type, file);
        if (success) {
          setOpenImportDialog(false);
          showSnackbar(`Uploaded successfully to ${activeTabConfig.label}!`);
          return true;
        }
        showSnackbar('Upload failed. Please try again.', true);
        return false;
      } catch (e) {
        showSnackbar('Failed to import file. Please try again.', true);
        return false;
      }
    },
    [activeTabConfig.label, activeTabConfig.type, showSnackbar],
  );

  const handleImportDialogOpen = useCallback(() => setOpenImportDialog(true), []);
  const handleImportDialogClose = useCallback(() => setOpenImportDialog(false), []);

  const downloadTemplate = useCallback(async () => {
    try {
      const result = await preawardAdminServiceApi.getUploadTemplateFile(activeTabConfig.type);
      if (!result) {
        showSnackbar('Failed to download template. Please try again.', true);
        return;
      }
      showSnackbar('Template downloaded successfully!');
    } catch (e) {
      showSnackbar('Failed to fetch data. Please try again later.', true);
    }
  }, [activeTabConfig.type, showSnackbar]);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue as number)}
        aria-label="Uploads tabs"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        {tabs.map((tab) => (
          <Tab key={`tab-${tab.label}`} label={tab.label} />
        ))}
      </Tabs>

      <StyledLayout>
        <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 3 }}>
          {activeTabConfig.stateTitle}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3, alignItems: 'start' }}>
          <UploadsActionButton
            onClick={handleImportDialogOpen}
            title={activeTabConfig.stateButtonTitle}
            subtitle={activeTabConfig.stateButtonSubtitle}
          />
          <UploadsActionButton
            onClick={downloadTemplate}
            icon={<DownloadRounded sx={{ fontSize: 32 }} />}
            title="Download Template"
            subtitle="Download the template to import data"
          />
        </Box>
      </StyledLayout>
      <UploadDialog open={openImportDialog} onClose={handleImportDialogClose} onImport={handleOnImport} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={() => setSnackbarOpen(false)}
        data-testid="upload-snackbar"
      >
        <Alert severity={error ? 'error' : 'success'} sx={{ width: '100%' }} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(UploadManager);
