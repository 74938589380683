import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface FundingDetailsSelectProps {
  label: string;
  menuItems: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  minWidth: 120,
  fontWeight: 500,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  flexGrow: 1,
  borderRadius: theme.shape.borderRadius,
  height: 40,
  fontSize: theme.typography.body2.fontSize,
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledOption = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

function FundingDetailsSelect({ label, value, menuItems, onChange }: Readonly<FundingDetailsSelectProps>) {
  return (
    <StyledFormControl>
      <StyledTypography variant="body2">{label}</StyledTypography>
      <StyledSelect
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        title={`select${label}`}
        data-testid={`select${label}`}
      >
        {menuItems.map((menuItem) => (
          <StyledOption key={menuItem.value} value={menuItem.value}>
            {menuItem.label}
          </StyledOption>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
}

export default FundingDetailsSelect;
