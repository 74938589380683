import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import FundingDetailsSelect from './funding-details-select';

import { trackException } from '@/application-insights';
import FundingIcon from '@/assets/icons/funding.svg';
import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import preawardServiceApi from '@/forms/services/preaward-api';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: 'transparent',
});

const UpdateButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': { backgroundColor: theme.palette.primary.dark },
}));

interface FundingDetailsProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (funderName: string, pricingScheme?: string) => void;
}

function FundingDetailsModal({ open, onClose, onUpdate }: Readonly<FundingDetailsProps>) {
  const theme = useTheme();
  const { fundingDetails } = useBudgetStateContext();
  const [funderId, setFunderId] = useState<string>('');
  const [pricingSchemeName, setPricingSchemeName] = useState<string | undefined>(undefined);

  const [funderOptions, setFunderOptions] = useState<{ value: string; label: string }[]>([]);
  const [pricingSchemeOptions, setPricingSchemeOptions] = useState<{ value: string; label: string }[]>([]);

  const handleUpdate = () => {
    onUpdate(funderId, pricingSchemeName);
    onClose();
  };

  useEffect(() => {
    setFunderId(fundingDetails.funderId);
    setPricingSchemeName(fundingDetails.pricingSchemeName);
  }, [fundingDetails]);

  useEffect(() => {
    const getFunderOptions = async () => {
      const funders = await preawardServiceApi.getFunders();
      const apiFunderOptions = funders.map((funder) => ({ value: funder.id, label: funder.name }));
      setFunderOptions(apiFunderOptions);
    };

    getFunderOptions().catch(trackException);
  }, []);

  useEffect(() => {
    const getPricingSchemeOptions = async () => {
      const funderResponse = await preawardServiceApi.getFunderById(funderId);
      const apiPricingSchemeOptions = funderResponse.pricingSchemes.map((pricingScheme) => ({
        value: pricingScheme.name,
        label: pricingScheme.name,
      }));
      setPricingSchemeOptions(apiPricingSchemeOptions);
    };

    if (funderId !== '') getPricingSchemeOptions().catch(trackException);
  }, [funderId]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>
        <Box display="flex" alignItems="center">
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pb: 0.5 }}>
              <img src={FundingIcon} alt="Import" style={{ width: 18, height: 18 }} />
              <Typography variant="h6">Funding details</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              This will impact funder costs, income and funder view of your budget
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small" aria-label="close">
            <Close />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <FundingDetailsSelect
          label="Funder Name"
          value={funderId}
          onChange={(e) => setFunderId(e)}
          menuItems={funderOptions}
        />
        <FundingDetailsSelect
          label="Pricing Scheme"
          value={pricingSchemeName ?? ''}
          onChange={(e) => setPricingSchemeName(e)}
          menuItems={pricingSchemeOptions}
        />
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Button onClick={onClose}>Cancel</Button>
        <UpdateButton onClick={handleUpdate} variant="contained">
          Update
        </UpdateButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default FundingDetailsModal;
