// eslint-disable-next-line import/no-unassigned-import
import '@/App.css';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Button, CssBaseline, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';

import CostingsBanner from './costings-banner';
import { useCostingReviewContext } from './costings-review-context';
import CostingsSection from './costings-section';

import { appInsights } from '@/application-insights';
import themeCosting from '@/themeCosting';
import { CostingsDto, ErmCommand } from '@/types';

function CostingsReview() {
  const [searchParams] = useSearchParams();
  const { costingSections, addSection, setSections } = useCostingReviewContext();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const formId = searchParams.get('formId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageId = searchParams.get('pageId')!;

  useEffect(() => {
    if (costingSections.length > 0) {
      return;
    }

    fetch(`/api/preaward/costings-template?formId=${formId}&pageId=${pageId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return Promise.all([]);
      })
      .then((data: CostingsDto) => {
        setSections(data.sections);
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  }, [costingSections, formId, pageId, setSections]);

  const handleAddCostingSection = () => {
    addSection();
  };

  const saveTemplate = (updatedPageId: string) => {
    fetch('/api/preaward/costings-template', {
      method: 'POST',
      body: JSON.stringify({
        formId,
        pageId: updatedPageId,
        sections: costingSections,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        window.parent.postMessage({ command: 'templateSaved', value: updatedPageId }, '*');
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const deleteTemplate = (pageIdToDelete: string) => {
    fetch(`/api/preaward/costings-template?formId=${formId}&pageId=${pageIdToDelete}`, {
      method: 'DELETE',
    }).catch((err: Error) => {
      appInsights.trackException({
        error: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  };

  const messageSwitch = (command: string) =>
    ({
      save: saveTemplate,
      delete: deleteTemplate,
    }[command]);

  const handleMessage = (ev: MessageEvent<ErmCommand>) => {
    const action = messageSwitch(ev.data.command);

    if (!action) {
      return;
    }

    action(ev.data.pageId);
  };

  useEventListener('message', handleMessage);
  return (
    <ThemeProvider theme={themeCosting}>
      <CssBaseline>
        <Grid container spacing={2} width="100%">
          <Grid xs={0.5} />
          <Grid xs={11}>
            <Grid xs={12}>
              <CostingsBanner />
            </Grid>
            {costingSections.map((section) => (
              <Grid xs={12} key={section.id}>
                <CostingsSection section={section} />
              </Grid>
            ))}

            <Button variant="contained" onClick={() => handleAddCostingSection()} sx={{ margin: 2 }} color="secondary">
              Add Section
            </Button>
          </Grid>
        </Grid>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default CostingsReview;
