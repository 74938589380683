import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

import { DropdownColumnDefinition } from '@/types';

const addDropdownColumnDefinition = (ddColumn: DropdownColumnDefinition, readonly: boolean, colDefs: GridColDef) => ({
  ...colDefs,
  type: 'singleSelect',
  editable: !readonly,
  valueOptions: ddColumn.options.map((option) => option.value),
  renderCell: (cellValues: GridRenderCellParams) =>
    cellValues.value ? (
      <Grid container>{cellValues.value}</Grid>
    ) : (
      <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
        <Grid item xs={10}>
          Select
        </Grid>

        <Grid item xs={2} sx={{ paddingTop: 0.6 }}>
          <ArrowDropDownIcon color="primary" />
        </Grid>
      </Grid>
    ),
});

export default addDropdownColumnDefinition;
