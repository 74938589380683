export const funderTypes = [
  'BioTechnology and Biological Sciences Research Council',
  'Medical Research Council',
  'Natural Environment Research Council',
  'Engineering and Physical Sciences Research Council',
  'Economic and Social Research Council',
  'Arts & Humanities Research Council',
  'Science & Technology Facilities Council',
  'UK Research and Innovation',
  'Other',
  'UK-based charities',
  'UK based charities (other)',
  'UK central government bodies/local authorities, health and hospital authorities',
  'UK central government tax credits for research and development expenditure',
  'UK industry, commerce and public corporations',
  'UK other sources',
  'EU government bodies',
  'EU-based charities (open competitive process)',
  'EU industry, commerce and public corporations',
  'EU (excluding UK) other',
  'Non-EU-based charities (open competitive process)',
  'Non-EU industry, commerce and public corporations',
  'Non-EU other',
];

export interface Funder {
  [key: string]: unknown;
  name: string;
  funderType: string;
  description?: string | null;
  email?: string | null;
  url?: string | null;
  address?: Address | null;
  status?: string | null;
}

export interface Address {
  [key: string]: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  country: string;
  postCode: string;
}

export interface FunderSummary {
  id: string;
  name: string;
  funderType: string;
  status: string;
}

export interface FunderSummaryResponse {
  funders: FunderSummary[];
  pageNumber: number;
  total: number;
}
export type BarMsg = {
  success: string;
  error: string;
};

export const SnackBarMsg: BarMsg = {
  success: 'Funder successfully published',
  error: 'Funder failed to publish',
};

export const SnackBarUpdateMsg: BarMsg = {
  success: 'Funder successfully updated',
  error: 'Funder failed to update',
};
