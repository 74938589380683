import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';

import ImportIcon from '@/assets/icons/import.svg';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['.csv'];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: 'transparent',
});

const DropZone = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  textAlign: 'center',
  cursor: 'pointer',
  border: `1px dashed ${theme.palette.grey[400]}`,
  background: theme.palette.grey[100],
  '&:hover': { backgroundColor: theme.palette.grey[200] },
}));

const SelectedFile = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.primary.main}`,
  background: alpha(theme.palette.primary.main, 0.1),
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grey[300]}`,
  fontWeight: 700,
  borderRadius: theme.shape.borderRadius,
}));

const AddButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': { backgroundColor: theme.palette.primary.dark },
}));

interface UploadDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (file: File) => Promise<boolean>;
}

function UploadDialog({ open, onClose, onImport }: Readonly<UploadDialogProps>) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const validateFile = useCallback((file: File): boolean => {
    setError(null);
    if (!ALLOWED_FILE_TYPES.includes(file.name.slice(file.name.lastIndexOf('.')).toLowerCase())) {
      setError('Invalid file type. Please upload a CSV file.');
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError(`File size exceeds ${MAX_FILE_SIZE / 1024 / 1024}MB limit.`);
      return false;
    }
    return true;
  }, []);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file && validateFile(file)) {
        setSelectedFile(file);
      }
    },
    [validateFile],
  );

  const handleDataTransfer = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && validateFile(file)) {
        setSelectedFile(file);
      }
    },
    [validateFile],
  );

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setError(null);
  }, []);

  const handleImport = useCallback(async () => {
    if (selectedFile) {
      setIsImporting(true);
      try {
        const success = await onImport(selectedFile);
        if (success) {
          handleRemoveFile();
          onClose();
        } else {
          setError('Import failed. Please try again.');
        }
      } finally {
        setIsImporting(false);
      }
    }
  }, [selectedFile, onImport, handleRemoveFile, onClose]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={fullScreen} title="Upload Dialog">
      <StyledDialogTitle>
        <Box display="flex" alignItems="center">
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pb: 0.5 }}>
              <img src={ImportIcon} alt="Import" style={{ width: 18, height: 18 }} />
              <Typography variant="h6">Import Pay Scales</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              Upload a CSV file to import pay scales
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        {!selectedFile ? (
          <DropZone
            onClick={() => fileInputRef.current?.click()}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDataTransfer}
            role="button"
            tabIndex={0}
            aria-label="Select file or drag and drop"
            data-testid="dropzone"
          >
            <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
              Select file to import
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              or drag and drop here
            </Typography>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              accept={ALLOWED_FILE_TYPES.join(',')}
              data-testid="fileInput"
            />
          </DropZone>
        ) : (
          <SelectedFile>
            <Typography variant="body2">
              {selectedFile.name} ({(selectedFile.size / 1024).toFixed(2)} KB)
            </Typography>
            <IconButton onClick={handleRemoveFile} size="small" aria-label="remove file" title="Remove file">
              <CloseIcon fontSize="small" />
            </IconButton>
          </SelectedFile>
        )}
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 2, pb: 2 }}>
        <CancelButton variant="outlined" onClick={onClose} aria-label="cancel import">
          Cancel
        </CancelButton>
        <AddButton
          variant="contained"
          disabled={!selectedFile || isImporting}
          onClick={handleImport}
          aria-label="import file"
        >
          {isImporting ? 'Importing...' : 'Import'}
        </AddButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default React.memo(UploadDialog);
