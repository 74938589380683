import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import NumbersIcon from '@mui/icons-material/Numbers';
import TitleIcon from '@mui/icons-material/Title';
import { Box, Button, Dialog, DialogTitle, FormControl, Grid, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Guid } from 'typescript-guid';

import CalculateColumForm from './calculated-column-form';
import DateTimeColumnForm from './date-time-column-form';
import DropDownColumnForm from './dropdown-column-form';
import ExternalColumnForm from './external-column-form';
import NumberColumnForm from './number-column-form';
// eslint-disable-next-line import/no-cycle
import TableColumnForm from './table-column-form';
import TextColumnForm from './text-column-form';

import { useCostingReviewContext } from '@/review/costings-review-context';
import {
  CalculatedColumnDefinition,
  ColumnDefinition,
  ColumnValueType,
  DateTimeColumnDefinition,
  DropdownColumnDefinition,
  ExternalColumnDefinition,
  NumberColumnDefinition,
  Status,
  TableColumnDefinition,
  TextColumnDefinition,
} from '@/types';

const columnValueTypeMap = new Map<ColumnValueType, { label: string; image: JSX.Element }>([
  [ColumnValueType.text, { label: 'Text', image: <TitleIcon fontSize="inherit" /> }],
  [
    ColumnValueType.datetime,
    {
      label: 'Datetime',
      image: <CalendarMonthOutlinedIcon fontSize="inherit" />,
    },
  ],
  [ColumnValueType.number, { label: 'Number', image: <NumbersIcon fontSize="inherit" /> }],
  [ColumnValueType.calculated, { label: 'Calculated', image: <CalculateIcon fontSize="inherit" /> }],
  [
    ColumnValueType.dropdown,
    {
      label: 'Dropdown',
      image: <ExpandCircleDownIcon fontSize="inherit" />,
    },
  ],
  [
    ColumnValueType.table,
    {
      label: 'Table',
      image: <ExpandCircleDownIcon fontSize="inherit" />,
    },
  ],
  [
    ColumnValueType.external,
    {
      label: 'External',
      image: <DatasetOutlinedIcon fontSize="inherit" />,
    },
  ],
]);

function ColumnTypeForm(
  props: Readonly<{
    columnValueType: ColumnValueType;
    columns: ColumnDefinition[];
    nestedColumns: ColumnDefinition[];
    columnToEdit?: ColumnDefinition;
    sectionId: string;
    categoryId: string;
    addColumn?: (column: ColumnDefinition) => void;
    deleteColumn?: (columnName: string) => void;
    updateColumn?: (oldColumnName: string, column: ColumnDefinition) => void;
  }>,
) {
  if (props.columnValueType === ColumnValueType.number) {
    return <NumberColumnForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  if (props.columnValueType === ColumnValueType.text) {
    return <TextColumnForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  if (props.columnValueType === ColumnValueType.datetime) {
    return <DateTimeColumnForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  if (props.columnValueType === ColumnValueType.calculated) {
    return <CalculateColumForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  if (props.columnValueType === ColumnValueType.dropdown) {
    return <DropDownColumnForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  if (props.columnValueType === ColumnValueType.table) {
    return (
      <TableColumnForm
        nestedColumns={props.nestedColumns}
        columns={props.columns}
        columnToEdit={props.columnToEdit}
        sectionId={props.sectionId}
        categoryId={props.categoryId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addColumn={props.addColumn!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        deleteColumn={props.deleteColumn!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updateColumn={props.updateColumn!}
      />
    );
  }

  if (props.columnValueType === ColumnValueType.external) {
    return <ExternalColumnForm columns={props.columns} columnToEdit={props.columnToEdit} />;
  }

  return null;
}

interface ModalProps {
  status: Status;
  columnToEdit?: ColumnDefinition;
  sectionId: string;
  categoryId: string;
  open: boolean;
  columns: ColumnDefinition[];
  handleClose: () => void;
  handleAdd: (sectionId: string, categoryId: string, column: ColumnDefinition) => void;
  handleUpdate: (sectionId: string, categoryId: string, existingColumnName: string, column: ColumnDefinition) => void;
}

const defaultProps = {
  columnToEdit: {
    $type: ColumnValueType.none,
    name: '',
    expression: '',
    options: [],
    columnDefinitions: [],
  },
};

function AddColumnDialog(props: Readonly<ModalProps>) {
  const { open, columnToEdit, handleClose, status, columns, handleAdd, handleUpdate } = props;
  const methods = useForm<
    | TextColumnDefinition
    | DateTimeColumnDefinition
    | NumberColumnDefinition
    | CalculatedColumnDefinition
    | DropdownColumnDefinition
    | TableColumnDefinition
    | ExternalColumnDefinition
  >({
    defaultValues: defaultProps.columnToEdit,
  });
  const columnValueType = methods.watch('$type');
  const [nestedColumns, setNestedColumns] = useState<ColumnDefinition[]>([]);
  const { deleteColumn } = useCostingReviewContext();

  const addColumn = (column: ColumnDefinition) => {
    setNestedColumns((existing) => [...existing, column]);
  };

  const updateColumn = (oldColumnName: string, column: ColumnDefinition) => {
    setNestedColumns((existing) => {
      const updatedColumns = [...existing];
      const index = updatedColumns.findIndex((col) => col.name === oldColumnName);
      updatedColumns[index] = column;
      return updatedColumns;
    });
  };

  const handleDeleteColumn = (columnName: string) => {
    setNestedColumns((existing) => existing.filter((col) => col.name !== columnName));
    deleteColumn(props.sectionId, props.categoryId, columnName);
  };

  const onSubmit: SubmitHandler<ColumnDefinition> = (columnDefinition: ColumnDefinition) => {
    let tableColumn = columnDefinition as TableColumnDefinition;
    if (nestedColumns?.length > 0) {
      tableColumn = columnDefinition as TableColumnDefinition;
      tableColumn.columnDefinitions = nestedColumns;
    }

    if (columnToEdit?.name) {
      handleUpdate(props.sectionId, props.categoryId, columnToEdit?.name, tableColumn);
    } else {
      handleAdd(props.sectionId, props.categoryId, tableColumn);
    }
    setNestedColumns([]);
    methods.reset(defaultProps.columnToEdit);
    handleClose();
  };

  useEffect(() => {
    if (status === Status.UPDATE) {
      const nestedCols = props.columnToEdit?.name
        ? (props.columnToEdit as TableColumnDefinition).columnDefinitions
        : [];
      setNestedColumns(nestedCols);
      methods.reset(columnToEdit);
    } else {
      setNestedColumns([]);
      methods.reset(defaultProps.columnToEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, columnToEdit]);

  const buttonStyle = {
    disabledButton: {
      background: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.12)' },
    },
    enabledButton: {
      bgcolor: 'secondary.main',
      '&:hover': { backgroundColor: 'rgba(17, 82, 147)' },
    },
  };

  function IsButtonDisabled(): boolean {
    return !methods.formState.isValid || !methods.formState.isDirty;
  }

  const formKey = Guid.create().toString();

  return (
    <Dialog
      PaperProps={{ sx: { width: '40%' } }}
      maxWidth="md"
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>{status === Status.UPDATE ? 'Update' : 'Add'} Column</DialogTitle>
      <FormProvider {...methods}>
        <form
          id={formKey}
          onSubmit={(e) => {
            e.stopPropagation();
            return methods.handleSubmit(onSubmit)(e);
          }}
        >
          <FormControl fullWidth>
            <Grid container paddingX={2}>
              <Grid xs={12} item>
                <Box sx={{ mb: 2 }}>
                  <Controller
                    name="$type"
                    defaultValue={ColumnValueType.none}
                    rules={{ required: true }}
                    control={methods.control}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        fullWidth
                        size="small"
                        id="columnType"
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        ref={ref}
                        name={name}
                        value={value}
                        disabled={status === Status.UPDATE}
                      >
                        <MenuItem disabled value="">
                          Select...
                        </MenuItem>
                        {Array.from(columnValueTypeMap).map(([key, option]) => (
                          <MenuItem key={key} value={key}>
                            <Grid container alignItems="center" className="ColumnTypeOption">
                              <Grid xs={1} sx={{ mt: 0.5 }} item>
                                {option.image}
                              </Grid>
                              <Grid xs={11} item>
                                {option.label}
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ mb: 2 }}>
                  <ColumnTypeForm
                    columnValueType={columnValueType}
                    columns={columns}
                    nestedColumns={nestedColumns}
                    columnToEdit={columnToEdit}
                    sectionId={props.sectionId}
                    categoryId={props.categoryId}
                    addColumn={addColumn}
                    deleteColumn={handleDeleteColumn}
                    updateColumn={updateColumn}
                  />
                </Box>
              </Grid>
              <Grid xs={4} item>
                <Box sx={{ mb: 2 }}>
                  <Button
                    variant="text"
                    onClick={() => {
                      methods.reset();
                      handleClose();
                    }}
                    id="CancelButton"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid xs={8} item>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    id="AddColumnButton"
                    form={formKey}
                    type="submit"
                    variant="contained"
                    className={IsButtonDisabled() ? 'disabledButton' : 'enabledButton'}
                    sx={IsButtonDisabled() ? buttonStyle.disabledButton : buttonStyle.enabledButton}
                  >
                    {status === Status.UPDATE ? 'Update Column' : 'Add Column'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </FormProvider>
    </Dialog>
  );
}
AddColumnDialog.defaultProps = defaultProps;
export default AddColumnDialog;
