import { ChevronRight } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AdminAppBar from '@/admin/components/admin-appbar';
import { TabConfig } from '@/admin/components/upload-manager';
import { UploadType } from '@/admin/services/preaward-api';

interface ConfigTile {
  title: string;
  description: string;
  url: string;
  state?: TabConfig[];
}

function ConfigurationTile({ title, description, url, state }: Readonly<ConfigTile>) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper
        elevation={0}
        sx={{
          height: '100%',
          borderRadius: 2,
          px: 2,
          pb: 1.5,
          pt: 1,
          border: '1px solid',
          borderColor: 'grey.300',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
        data-testid="config-tile"
        onClick={() => navigate(url, { state })}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: '0.75rem', maxLines: 2, fontWeight: 400 }}
            >
              {description}
            </Typography>
          </Box>
          <IconButton color="secondary" sx={{ fontSize: '0.875rem' }}>
            <ChevronRight fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
    </Grid>
  );
}

const configTiles: ConfigTile[] = [
  {
    title: 'Costing Manager',
    description: 'Create and map cost categories against finance codes across all dimensions',
    url: '/admin/costing-manager',
  },
  {
    title: 'Pricing Manager',
    description:
      'Create and manage funder templates, rules, salary bands, on-costs and more catering to funder requirements',
    url: '/admin/pricing-manager',
  },
  {
    title: 'Facilities & Services',
    description:
      'Define university provided facilities and services to be utilised for a research project thereby accounting it for budget as well',
    url: '/admin/facilities-services',
    state: [
      {
        label: 'Facilities & Services',
        type: UploadType.FacilitiesAndServices,
        stateTitle: 'Get started with facilities & services imports',
        stateButtonTitle: 'Import Facilities & Services',
        stateButtonSubtitle: 'Add facilities & services data from CSV',
      },
    ],
  },
  {
    title: 'Funders',
    description: 'Create and manage funders – basic details, type, size and more',
    url: '/admin/funders',
  },
  {
    title: 'Partners',
    description: 'Create and manage different partner organisations here. Used collaboration purposes',
    url: '/admin/partners',
    state: [
      {
        label: 'Partners',
        type: UploadType.Partner,
        stateTitle: 'Get started with partners imports',
        stateButtonTitle: 'Import Partners',
        stateButtonSubtitle: 'Add partners data from CSV',
      },
      {
        label: 'Universities',
        type: UploadType.University,
        stateTitle: 'Get started with universities imports',
        stateButtonTitle: 'Import Universities',
        stateButtonSubtitle: 'Add universities data from CSV',
      },
    ],
  },
  {
    title: 'Organisational Units',
    description: 'Create and manage all departmental units of your organisation and overhead rates.',
    url: '/admin/organisational-units',
    state: [
      {
        label: 'Organisational Units',
        type: UploadType.OrganisationalUnit,
        stateTitle: 'Get started with organisational units imports',
        stateButtonTitle: 'Import Organisational Units',
        stateButtonSubtitle: 'Add organisational units data from CSV',
      },
    ],
  },
  {
    title: 'Staff',
    description:
      'Create and manage staff data, that along with basic details also include pay scales, project roles, inflation rates, etc',
    url: '/admin/staff',
    state: [
      {
        label: 'Staff',
        type: UploadType.Staff,
        stateTitle: 'Get started with staff imports',
        stateButtonTitle: 'Import Staff',
        stateButtonSubtitle: 'Add staff data from CSV',
      },
      {
        label: 'Pay Scales',
        type: UploadType.PayScale,
        stateTitle: 'Get started with pay scale imports',
        stateButtonTitle: 'Import Pay Scales',
        stateButtonSubtitle: 'Add pay scales from CSV',
      },
      {
        label: 'Overhead Rates',
        type: UploadType.OverheadRates,
        stateTitle: 'Get started with overhead rates imports',
        stateButtonTitle: 'Import Overhead Rates',
        stateButtonSubtitle: 'Add overhead rates from CSV',
      },
    ],
  },
  {
    title: 'Non-staff',
    description:
      'Create and manage non-staff items of your organisation that will be utilised by staffs for any research project',
    url: '/admin/non-staff',
    state: [
      {
        label: 'Non-Staff',
        type: UploadType.NonStaff,
        stateTitle: 'Get started with non-staff imports',
        stateButtonTitle: 'Import Non-Staff',
        stateButtonSubtitle: 'Add non-staff data from CSV',
      },
    ],
  },
  {
    title: 'Inflation Rates',
    description: 'Add and/or manage inflation rates for staffs, non-staff, project roles and more',
    url: '/admin/inflation-rates',
    state: [
      {
        label: 'Staff Type Rates',
        type: UploadType.StaffTypeRates,
        stateTitle: 'Get started with staff type rates imports',
        stateButtonTitle: 'Import Staff Type Rates',
        stateButtonSubtitle: 'Add staff type rates data from CSV',
      },
      {
        label: 'Project Role Rates',
        type: UploadType.ProjectRoleRates,
        stateTitle: 'Get started with project role rates imports',
        stateButtonTitle: 'Import Project Role Rates',
        stateButtonSubtitle: 'Add project role rates data from CSV',
      },
    ],
  },
];

function HomePage() {
  return (
    <Box>
      <AdminAppBar title="Admin Configuration" />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {configTiles.map((tile) => (
            <ConfigurationTile {...tile} key={tile.title} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default HomePage;
