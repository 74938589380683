import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { useCostingFormContext } from '@/forms/contexts/costings-forms-context';
import { SectionTotal } from '@/types';

function CostinsBanner() {
  const { categoryTotals } = useCostingFormContext();
  const [totalCosts, setTotalCosts] = useState<SectionTotal[]>([]);

  function groupSubtotals(subtotals: SectionTotal[]): SectionTotal[] {
    const result = subtotals?.reduce((acc: SectionTotal[], cur) => {
      const index = acc.findIndex((obj) => obj.sectionId === cur.sectionId);
      if (index !== -1) {
        acc[index].total += Number(cur.total);
      } else {
        acc.push({
          sectionId: cur.sectionId,
          sectionName: cur.sectionName,
          categoryId: cur?.categoryId,
          total: Number(cur.total),
        });
      }
      return acc;
    }, []);
    return result;
  }

  function getTotalCost(): number {
    const total = totalCosts.reduce((acc: number, cur: SectionTotal) => acc + cur.total, 0);
    return total || 0;
  }

  useEffect(() => {
    const sectionTotals = groupSubtotals(categoryTotals);
    setTotalCosts(sectionTotals);
  }, [categoryTotals]);

  return (
    <Paper>
      <Grid container padding={3}>
        <Grid xs={12} paddingBottom={2} item>
          <Typography variant="subtitle1">Budget</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            <Paper sx={{ bgcolor: 'primary.light' }}>
              <Grid item padding={1.5}>
                <Typography variant="subtitle2">Total cost</Typography>
              </Grid>
              <Grid item padding={1.5}>
                <Box display="flex" justifyContent="flex-end" data-testid="totalcost">
                  <Typography variant="h4">{getTotalCost()}</Typography>
                </Box>
              </Grid>
            </Paper>
          </Grid>
          {totalCosts.map((section: SectionTotal) => (
            <Grid xs={3} item key={section.sectionId}>
              <Paper sx={{ bgcolor: 'primary.light' }}>
                <Grid item padding={1.5}>
                  <Typography variant="subtitle2">{section.sectionName || 'Section Name'}</Typography>
                </Grid>
                <Grid item padding={1.5}>
                  <Box display="flex" justifyContent="flex-end" data-testid="sectioncost">
                    <Typography variant="h4">
                      <section>{section.total}</section>
                    </Typography>
                  </Box>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CostinsBanner;
