import { MenuItem } from '@mui/material';
import {
  ColumnMenuPropsOverrides,
  DataGridPro,
  GridColumnMenuContainer,
  GridColumnMenuProps,
} from '@mui/x-data-grid-pro';

import { DeleteColumnMenuItem } from '@/review/costing-delete';
import { ColumnDefinition } from '@/types';

function CustomColumnMenu(props: GridColumnMenuProps & ColumnMenuPropsOverrides) {
  const { handleEditColumn, handleDeleteColumn, hideMenu, colDef, open } = props;
  let deleteIsDisabled = false;
  if (props.colDef.headerName === 'Funds Requested') {
    deleteIsDisabled = true;
  }

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} colDef={colDef} open={open}>
      <MenuItem
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          handleEditColumn(colDef.headerName!);
        }}
      >
        Edit
      </MenuItem>
      <DeleteColumnMenuItem
        isDisabled={deleteIsDisabled}
        title="Delete Column?"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        callback={() => handleDeleteColumn(colDef.headerName!)}
      />
    </GridColumnMenuContainer>
  );
}

function CustomCostingsTable(
  props: Readonly<{
    deleteColumn(columnName: string): void;
    editColumn(columnName: string): void;
    columns: ColumnDefinition[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: any[];
  }>,
) {
  const tableColumns = props.columns.map((column) => ({
    field: column.name,
    headerName: column.name,
    sortable: false,
    flex: 1,
  }));

  return (
    <DataGridPro
      rows={props.rows}
      columns={tableColumns}
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      hideFooter
      slots={{
        columnMenu: CustomColumnMenu,
      }}
      slotProps={{
        columnMenu: {
          handleEditColumn: props.editColumn,
          handleDeleteColumn: props.deleteColumn,
        },
      }}
    />
  );
}

export default CustomCostingsTable;
