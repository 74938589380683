import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';

const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

function skeletonColDefs() {
  return <Skeleton width={getRandomArbitrary(60, 200)} variant="text" />;
}

const columns: GridColDef[] = ['Type', 'Project Cost', 'Funder Cost', 'Income', 'Surplus/Deficit'].map((name) => ({
  field: name,
  headerName: name,
  flex: 1,
  renderCell: () => skeletonColDefs(),
}));

const rows: GridValidRowModel[] = Array.from({ length: 10 }, (_, k) => ({
  id: k,
  Type: ' ',
  'Project Cost': ' ',
  'Funder Cost': ' ',
  Income: ' ',
  'Surplus/Deficit': ' ',
}));

export default function BudgetSkeleton() {
  const summaryTileNames: string[] = [
    'Project Cost',
    'Funder Cost',
    'Income',
    'FEC Recovery',
    'Overheads Contribution',
  ];
  return (
    <Grid container sx={{ px: 3 }}>
      <Grid item xs={12} id="title">
        <Typography variant="h4" sx={{ my: 2 }}>
          Project Budget
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <Grid id="summaryTiles" container spacing={1}>
          {summaryTileNames.map((name) => (
            <Grid key={name} item xs={2.4} height={50}>
              <Box
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 3,
                  marginTop: 0.5,
                  marginBottom: 2,
                  padding: 0.5,
                }}
              >
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Grid item xs={2} sx={{ display: 'block', margin: 'auto' }}>
                    <Skeleton variant="rounded" height={40} width={40} sx={{ marginLeft: 1, marginTop: 1 }} />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">{name}</Typography>
                    <Grid>
                      <Skeleton data-testid="loadingSkeleton" variant="text" />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} id="tabs">
        <Grid container>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', paddingTop: 2, borderBottom: 1, borderColor: 'divider' }} display="flex">
              {Array.from({ length: 5 }, (_, k) => (
                <Skeleton
                  data-testid="loadingSkeleton"
                  key={k}
                  variant="rectangular"
                  width={130}
                  height={30}
                  sx={{ marginRight: '4px' }}
                />
              ))}
            </Box>
            <Grid>
              <Typography variant="h6" sx={{ my: 3 }}>
                Project Budget Breakdown
              </Typography>
              <DataGridPro
                autoHeight
                rowHeight={40}
                columnHeaderHeight={40}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                hideFooterPagination
                hideFooter
                hideFooterRowCount
                hideFooterSelectedRowCount
                disableColumnReorder
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
