import { Box } from '@mui/material';
import Autocomplete, { AutocompleteProps, autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { useEffect, useState } from 'react';

import NameAvatarCell from '@/components/name-avatar-cell';
import { StaffModel, isStaffModel } from '@/types';
import { lowerFirstLetter } from '@/utils';
import preawardServiceApi from '@forms/services/preaward-api';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: '100%',
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.body2,
    padding: '1px 0',
    height: '100%',
    '& input': {
      padding: '0 16px',
      height: '100%',
    },
  },
})) as typeof Autocomplete;

export default function StaffAutocomplete(props: Readonly<GridRenderEditCellParams<StaffModel>>) {
  const { id, field, row } = props;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [staffList, setStaffList] = useState<StaffModel[]>([]);
  const path = props.path as string;

  const propertyName = lowerFirstLetter(props.propertyName as string) as keyof StaffModel;

  const apiRef = useGridApiContext();

  useEffect(() => {
    const getStaffData = async () => {
      const staffModel = await preawardServiceApi.getStaffByNameFilter(path, inputValue);
      setStaffList(staffModel.data);
    };

    if (inputValue) {
      const getData = setTimeout(() => {
        getStaffData().catch(() => {});
      }, 500);

      return () => clearTimeout(getData);
    }
    return undefined;
  }, [inputValue, path]);

  /// @ChandanInfonetica - This is temporary code, need to be removed after the implementation of the ID for columnns is costing template
  const autoCompleteUk = (grade: string, scalePoint: string) => ({
    Grade: grade,
    Point: scalePoint,
  });

  const autoCompleteAu = (grade: string, scalePoint: string) => ({
    'HEW/Level': grade,
    Step: scalePoint,
  });

  const handleChange = React.useCallback<NonNullable<AutocompleteProps<StaffModel, false, true, false>['onChange']>>(
    async (event, newValue: StaffModel) => {
      await apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });

      if (isStaffModel(newValue)) {
        apiRef.current.updateRows([
          {
            ...row,
            ...autoCompleteUk(newValue.grade, newValue.scalePoint),
            ...autoCompleteAu(newValue.grade, newValue.scalePoint),
            'Organisational Unit': newValue.organisationalUnit,
          },
        ]);
      }
    },
    [apiRef, field, id, row],
  );

  return (
    <StyledAutocomplete<StaffModel, false, true, false>
      open={open}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, value) => {
        setInputValue(value);

        if (!value) {
          setOpen(false);
        }
        setOpen(true);
      }}
      options={staffList}
      getOptionLabel={(option) => String(option[propertyName])}
      fullWidth
      disableClearable
      renderOption={(optionProps, option: StaffModel) => (
        <Box component="li" {...optionProps}>
          <NameAvatarCell text={option[propertyName]} />
        </Box>
      )}
      renderInput={(params) => (
        <InputBase
          autoFocus
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          {...params.InputProps}
        />
      )}
    />
  );
}
