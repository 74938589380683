import { Box, alpha } from '@mui/material';
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useCallback, useState } from 'react';

function CostingsTable(
  props: Readonly<{
    columns: GridColDef[];
    rows: GridRowModel[];
    uniqueKey: string;
    onProcessRowUpdate(newRow: GridValidRowModel): void;
    onRowClick(row: GridRowModel): void;
  }>,
) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const apiRef = useGridApiRef();
  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (!params.isEditable) {
        return;
      }

      const updatedModel = { ...cellModesModel };

      updatedModel[params.id] = {
        ...(updatedModel[params.id] || {}),
        [params.field]: { mode: GridCellModes.Edit },
      };

      Object.keys(updatedModel[params.id]).forEach((field) => {
        if (field !== params.field) {
          updatedModel[params.id][field] = { mode: GridCellModes.View };
        }
      });

      setCellModesModel(updatedModel);
    },
    [cellModesModel],
  );

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <Box
      id="nestedTable"
      sx={{
        width: '100%',
        '& .MuiDataGrid-root': {
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
    >
      <DataGridPro
        sx={(theme) => ({
          '.hierarchyLevel1': {
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            fontWeight: 'bold',
          },
          '.hierarchyLevel2': {
            bgcolor: alpha(theme.palette.primary.main, 0.06),
          },
          '& .MuiDataGrid-cell': {
            borderColor: alpha(theme.palette.primary.main, 0.1),
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            fontWeight: 500,
            fontSize: '0.875rem',
          },
          '& .MuiDataGrid-columnHeaders': {
            // backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.light,
            fontWeight: 'bold',
            fontSize: '0.775rem',
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        })}
        getRowClassName={(params) => {
          const { depth } = params.row as { depth: number };
          const depthClass: { [key: number]: string } = {
            0: 'hierarchyLevel1',
            1: 'hierarchyLevel2',
          };
          return depthClass[depth];
        }}
        getRowId={(row: GridRowModel) => row[props.uniqueKey] as GridRowId}
        apiRef={apiRef}
        autoHeight
        rowHeight={44}
        columnHeaderHeight={40}
        rows={props.rows}
        columns={props.columns}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        hideFooterPagination
        hideFooter
        hideFooterRowCount
        hideFooterSelectedRowCount
        disableColumnReorder
        disableColumnResize
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        processRowUpdate={(newRow) => {
          props.onProcessRowUpdate(newRow);
          return newRow;
        }}
        onRowClick={(params) => props.onRowClick(params.row as GridRowModel)}
      />
    </Box>
  );
}

export default CostingsTable;
